<template>
  <div>
    <Complete />
    <v-dialog v-model="showCardForm" content-class="white-two rounded-xl" persistent max-width="420px">
      <v-card class="pa-4">
        <v-card-title class="pb-2">
          <v-row>
            <v-col cols="12" class="d-flex justify-space-between mb-6">
              <div class="card-title">카드 등록</div>
              <v-icon
                color="default"
                @click="
                  showCardForm = false;
                  resetCardData();
                "
                >close</v-icon
              >
            </v-col>
            <v-col cols="12">
              <h2 class="font-weight-bold headline">💳 슈퍼멤버스를 구독할 <br />카드를 등록해 주세요</h2>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="pt-0">
          <v-row>
            <v-col cols="12" class="mb-6 card-subtitle">
              지금 결제 정보를 등록해 주시면 <span class="font-weight-bold">3개월 플랜 20% 할인권</span>을 제공해
              드려요.
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" class="mb-3">
                  <div class="mb-1">소유주 이름</div>
                  <v-text-field
                    v-model="cardDetail.name"
                    name="name"
                    :rules="[errors.first('name') || !errors.has('name')]"
                    placeholder="소유주 성함"
                    hide-details
                    single-line
                    outlined
                    solo
                    flat></v-text-field>
                </v-col>
                <v-col cols="12" class="mb-3">
                  <div class="mb-1">소유주 정보</div>
                  <v-text-field
                    v-model="cardDetail.birth"
                    name="birth"
                    v-validate="'required|min:6'"
                    :rules="[errors.first('birth') || !errors.has('birth')]"
                    maxlength="10"
                    placeholder="생년월일 6자리 또는 사업자번호 10자리"
                    hide-details
                    single-line
                    outlined
                    solo
                    flat></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mb-3">
                <v-col cols="12" class="mb-1">
                  <div>카드번호</div>
                </v-col>
                <v-col cols="12" class="hidden-md-and-up">
                  <v-text-field
                    v-model="cardDetail.number"
                    name="number"
                    :rules="[errors.first('number') || !errors.has('number')]"
                    placeholder="카드번호"
                    v-mask="'####-####-####-####'"
                    hide-details
                    single-line
                    outlined
                    solo
                    flat></v-text-field>
                </v-col>
                <v-col cols="12" md="3" class="hidden-sm-and-down pr-3">
                  <v-text-field
                    v-model="cardDetail.numberArray[0]"
                    name="number0"
                    :rules="[errors.first('number0') || !errors.has('number0')]"
                    @input="mergeNumber"
                    placeholder=""
                    v-mask="'####'"
                    hide-details
                    single-line
                    outlined
                    solo
                    flat></v-text-field>
                </v-col>
                <v-col cols="12" md="3" class="hidden-sm-and-down pr-3">
                  <v-text-field
                    v-model="cardDetail.numberArray[1]"
                    name="number1"
                    :rules="[errors.first('number1') || !errors.has('number1')]"
                    @input="mergeNumber"
                    placeholder=""
                    v-mask="'####'"
                    hide-details
                    single-line
                    outlined
                    solo
                    flat></v-text-field>
                </v-col>
                <v-col cols="12" md="3" class="hidden-sm-and-down pr-3">
                  <v-text-field
                    v-model="cardDetail.numberArray[2]"
                    name="number2"
                    :rules="[errors.first('number2') || !errors.has('number2')]"
                    @input="mergeNumber"
                    placeholder=""
                    v-mask="'####'"
                    hide-details
                    single-line
                    outlined
                    solo
                    flat></v-text-field>
                </v-col>
                <v-col cols="12" md="3" class="hidden-sm-and-down">
                  <v-text-field
                    v-model="cardDetail.numberArray[3]"
                    name="number3"
                    :rules="[errors.first('number3') || !errors.has('number3')]"
                    @input="mergeNumber"
                    placeholder=""
                    v-mask="'####'"
                    hide-details
                    single-line
                    outlined
                    solo
                    flat></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mb-6">
                <v-col :class="$vuetify.breakpoint.smAndDown ? 'mb-3' : 'mb-3 pr-3'" cols="12" md="6">
                  <div>유효기간</div>
                  <v-text-field
                    v-model="cardDetail.expiry"
                    name="expiry"
                    :rules="[errors.first('expiry') || !errors.has('expiry')]"
                    placeholder="MM/YY"
                    v-mask="'##/##'"
                    hide-details
                    single-line
                    outlined
                    solo
                    flat></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <div>비밀번호 앞 두자리</div>
                  <v-text-field
                    class="mb-1"
                    v-model="cardDetail.pwd_2digit"
                    name="pwd_2digit"
                    :append-icon="showPassword ? 'visibility_off' : 'visibility'"
                    :rules="[errors.first('pwd_2digit') || !errors.has('pwd_2digit')]"
                    :type="showPassword ? 'text' : 'password'"
                    placeholder="비밀번호 앞 두자리"
                    v-mask="'##'"
                    hide-details
                    single-line
                    outlined
                    solo
                    flat
                    @click:append="showPassword = !showPassword"></v-text-field>
                  <div class="card-password-helper">*법인카드 생략 가능</div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="mb-6">
                  <div>*등록해주신 카드로 바로 결제가 진행되지 않으며, 언제든지 삭제가 가능하니 안심하셔도 좋아요.</div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-0">
          <button
            class="submit-button white-two--text"
            :style="{ opacity: !isValidForm ? '0.2' : '1' }"
            flat
            :disabled="!isValidForm"
            @click="addCard()">
            저장
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Complete from '@/components/company/regads/store/Complete.vue';
import { mask } from 'vue-the-mask';
import userMixin from '@/components/shared/userMixin';

const defaultCreditCardData = {
  customer_uid: '',
  card_number: '',
  customer_name: '',
  number: '',
  numberArray: ['', '', '', ''],
  name: '',
  expiry: '',
  birth: '',
  pwd_2digit: '',
};

export default {
  components: {
    Complete,
  },
  mixins: [userMixin],
  created() {
    this.showCardForm = this.$route.query.showCardForm === 'true';
  },
  props: {},
  data() {
    return {
      cardDetail: defaultCreditCardData,
      invertedCard: false,
      showPassword: false,
    };
  },
  computed: {
    isValidForm() {
      const isNameValid = this.cardDetail.name.length > 0;
      const isBirthValid = this.cardDetail.birth.length === 6 || this.cardDetail.birth.length === 10;
      const isNumberValid = this.cardDetail.number.replace(/-/gi, '').length === 16;
      const isExpiryValid = this.cardDetail.expiry.length === 5;
      const isPwdValid = this.cardDetail.pwd_2digit.length === 2;

      return isNameValid && isBirthValid && isNumberValid && isExpiryValid && isPwdValid;
    },
  },
  directives: {
    mask,
  },
  watch: {
    cardDetail: {
      handler(newValue) {
        console.log('@cardDetail', newValue, this.user.id);
      },
      deep: true,
    },
  },
  methods: {
    async mergeNumber() {
      this.cardDetail.number = this.cardDetail.numberArray.join('-');
    },
    async addCard() {
      let registrationSuccess = false;

      try {
        this.cardDetail.card_number = this.cardDetail.number;
        this.cardDetail.customer_name = this.cardDetail.name;
        let cardData = JSON.parse(JSON.stringify(this.cardDetail));
        let tempExpiry = cardData.expiry.split('/');
        cardData.expiry = '20' + tempExpiry[1].trim() + '-' + tempExpiry[0].trim();

        let result = await this.axios.post('/company/user/card?userId=' + this.user.id, cardData);
        if (result.data.message) {
          this.$store.commit('setMsg', result.data.message);
          this.$store.commit('setShowSnackbar', true);
        } else {
          this.$store.commit('setMsg', '카드 정보가 저장되었습니다.');
          this.$store.commit('setShowSnackbar', true);
          this.resetCardData();
          registrationSuccess = true;
          this.showCardForm = false;
        }
      } catch (e) {
        this.$store.commit('setMsg', '카드 정보를 확인해 주세요.');
        this.$store.commit('setShowSnackbar', true);
        console.log(e);
        this.resetCardData();
      } finally {
        window.dataLayer.push({
          event: 'card_registration',
          card_registration_success: registrationSuccess,
        });
      }
    },
    resetCardData() {
      this.cardDetail.customer_uid = '';
      this.cardDetail.card_number = '';
      this.cardDetail.customer_name = '';
      this.cardDetail.number = '';
      this.cardDetail.numberArray = ['', '', '', ''];
      this.cardDetail.name = '';
      this.cardDetail.expiry = '';
      this.cardDetail.birth = '';
      this.cardDetail.pwd_2digit = '';
    },
  },
};
</script>

<style scoped lang="scss">
.card-title {
  font-size: 16px;
  font-weight: bold;
}

.card-subtitle {
  font-size: 14px;
  color: #000;
}

.card-password-helper {
  font-size: 11px;
  color: var(--default-light-color);
}

.submit-button {
  width: 100%;
  height: 44px;
  border-radius: 4px;
  background-color: var(--primary-light-color);
  color: white;
}
</style>
